
/* TABLETS */
@media screen and (min-width: 768px) and (max-width: 991px){
    .sobre-paragrafo {
        padding: 30px 50px;
        margin-left: 50px;
        margin-right: 50px;
    }
}

/* CELULARES */
@media screen and (max-width: 767px){
    .sobre h1{
        font-size: 20px;
    }
    
    .sobre-paragrafo {
        padding: 30px 40px;
        margin-left: 30px;
        margin-right: 30px;
    }
    
    .sobre-paragrafo p{
        font-size: 10px;
    }
}