@import url('https://fonts.googleapis.com/css2?family=Calistoga&display=swap');

/* RESET */
*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Calistoga", serif;
    font-weight: 400;
    font-style: normal;
}

/* BODY STYLE */
body{
    background-color: #849073;
}

/* SCROLL STYLE */
body::-webkit-scrollbar {
    width: 10px;               /* largura da scrollbar */
}

body::-webkit-scrollbar-track {
    background: #606953;        /* cor da track area */
}

body::-webkit-scrollbar-thumb {
    background-color: #3C190B;    /* cor do scroll */
    border-radius: 20px;       /* borda do scroll */
}

/* SELEÇÃO DAS PALAVRAS */
::selection {
    background-color: #874E50;
    color: #97A284;
}
