.footer-nav{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    column-gap: 50px;
    background-color: #B08283;
    padding: 25px;
}

.footer-nav-logo{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footer-nav-logo img{
    height: 130px;
}

.footer-nav-logo p{
    color: #3C190B;
    font-size: 18px;
}

.footer-nav-contact{
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 8px;
}

.footer-nav-contact-title{
    color: #3C190B;
    padding-bottom: 20px;
}

.footer-nav-contact p{
    color: #3C190B;
    font-size: 18px;
    cursor: pointer;
}

.footer-nav-contact p:hover{
    color: #7a3a21;
}

.footer-nav-navigation{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footer-nav-navigation-title{
    color: #3C190B;
    padding-bottom: 20px;
}

.footer-nav-navigation ul{
    list-style: none;
}

.footer-nav-navigation ul li a{
    text-decoration: none;
    color: #3C190B;
    font-size: 18px;
    cursor: pointer;
}

.footer-nav-navigation ul li a:hover{
    color: #7a3a21;
}

.footer-nav-follow{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footer-nav-follow-title{
    color: #3C190B;
    padding-bottom: 20px;
}

.footer-nav-follow ul{
    list-style: none;
}

.footer-nav-follow ul li a{
    text-decoration: none;
    color: #3C190B;
    font-size: 18px;
}

.footer-nav-follow ul li a:hover{
    color: #7a3a21;
}