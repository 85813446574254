.contato{
    padding-bottom: 60px;
}

.contato h1{
    text-align: center;
    color: #3C190B;
    font-size: 30px;
    padding-top: 30px;
    padding-bottom: 30px;
}

.contato-form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #00000011;
    margin: 0% 15%;
    padding: 40px;
    border-radius: 20px;
}

.contato-form form input{
    font-size: 18px;
    padding-left: 8px;
    color: #22290F;
    height: 60px;
    width: 700px;
    margin: 20px 0px;
    border: none;
    border-radius: 15px;
    background: rgb(187,203,165);
    background: linear-gradient(82deg, rgba(187, 203, 165, 0.13) 7%, rgba(224, 167, 167, 0.144) 74%);
    box-shadow: 0px 8px 10px 0px rgba(0,0,0,0.75) inset;
    -webkit-box-shadow: 0px 8px 10px 0px rgba(0,0,0,0.75) inset;
    -moz-box-shadow: 0px 8px 10px 0px rgba(0,0,0,0.75) inset;
}

.contato-form form input::placeholder{
    color: #606953;
    text-align: center;
    font-size: 16px;
}

.contato-form form input:focus{
    outline: 5px solid #4d5541;
}

.contato-form form textarea{
    font-size: 18px;
    padding-top: 15px;
    padding-left: 8px;
    color: #22290F;
    height: 200px;
    width: 700px;
    margin: 20px 0px;
    border: none;
    border-radius: 15px;
    background: rgb(187,203,165);
    background: linear-gradient(82deg, rgba(187, 203, 165, 0.13) 7%, rgba(224, 167, 167, 0.144) 74%);
    box-shadow: 0px 8px 10px 0px rgba(0,0,0,0.75) inset;
    -webkit-box-shadow: 0px 8px 10px 0px rgba(0,0,0,0.75) inset;
    -moz-box-shadow: 0px 8px 10px 0px rgba(0,0,0,0.75) inset;
    resize: none;
}

.contato-form form textarea:focus{
    outline: 5px solid #4d5541;
}

.contato-form form textarea::placeholder{
    color: #606953;
    text-align: center;
    font-size: 16px;
    padding-top: 20px;
}

.contato-form-btn{
    display: flex;
    justify-content: center;
    align-items: center;
}

.contato-form-btn button{
    height: 60px;
    width: 100px;
    margin: 20px 0px;
    border: none;
    border-radius: 15px;
    background: rgb(187,203,165);
    color: #3C190B;
    background: linear-gradient(82deg, rgba(187, 203, 165, 0.13) 7%, rgba(224, 167, 167, 0.144) 74%);
    box-shadow: 0px 8px 10px 0px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 8px 10px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 8px 10px 0px rgba(0,0,0,0.75); 
    font-size: 18px;
    cursor: pointer;
}

.contato-form-btn button:hover{
    background: linear-gradient(82deg, rgba(132, 145, 115, 0.13) 7%, rgba(136, 98, 98, 0.144) 74%);
}