.slider .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
}

.slider img{
    width: 90%;
    border-radius: 20px;
}
