
/* TABLETS */
@media screen and (min-width: 768px) and (max-width: 991px){
    .header-logo img{
        padding-left: 30px;
    }
    
    .header-nav ul{
        padding-right: 30px;
        column-gap: 40px;
    }
}

/* CELULARES */
@media screen and (max-width: 767px){
    .header{
        background-color: #B08283;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .header-logo img{
        height: 100px;
        padding-left: 0px;
    }

    .header-nav ul{
        display: none;
    }
}