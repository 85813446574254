

/* TABLETS */
@media screen and (min-width: 768px) and (max-width: 991px){
    .contato-form{
        margin: 0% 30px;
    }
    
    .contato-form form input{
        width: 600px;
    }
    
    .contato-form form textarea{
        width: 600px;
    }
}

/* CELULARES */
@media screen and (max-width: 767px){
    .contato{
        padding-bottom: 15px;
    }

    .contato h1{
        font-size: 20px;
    }

    .contato-form{
        margin: 0% 15px;
    }
    
    .contato-form form input{
        height: 35px;
        width: 250px;
    }

    .contato-form form input::placeholder{
        font-size: 10px;
    }
    
    .contato-form form textarea{
        height: 150px;
        width: 250px;
    }

    .contato-form form textarea::placeholder{
        font-size: 10px;
    }  
    
    .contato-form-btn button{
        height: 40px;
        width: 100px;
        margin: 2px 0px;
        font-size: 12px;
    }
}  