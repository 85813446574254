.header{
    background-color: #B08283;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header-logo img{
    height: 140px;
    padding-left: 80px;
}

.header-nav ul{
    padding-right: 80px;
    list-style: none;
    display: flex;
    flex-direction: row;
    column-gap: 80px;
    justify-content: space-evenly;
    align-items: center;
}

.header-nav ul li a{
    text-decoration: none;
    font-size: 20px;
    color: #3C190B;
    cursor: pointer;
}

.header-nav ul .carShopAuto:hover{
    animation: shake .4s;
}

@keyframes shake{
    0%{
        transform: rotate(0);
    }
    25%{
        transform: rotate(-20deg);
    }
    50%{
        transform: rotate(20deg);
    }
    75%{
        transform: rotate(-20deg);
    }
    100%{
        transform: rotate(20deg);
    }
}

